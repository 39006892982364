<template>
  <div>
    <div class="super">
      <div class="holder">
        <SmallNav />
        <div class="properties_count">
          <img
            @click="back"
            src="../assets/images/thickbackarrow.svg"
            alt="back"
          />
          <h6 @click="back">Back to properties</h6>
        </div>

        <div class="pmr-intro">
          <p>
            Pay My Rent (PMR) is a Rent Financing service designed for tenants
            who live in properties not managed by Shelta.
          </p>
        </div>
      </div>

      <div v-if="!proceedToApply" class="eligibilty_holder">
        <h4>Eligibility Check</h4>
        <hr />
        <p>
          We carry our eligibility check through Government Licenced Credit
          Bureau (First Credit). Shelta does not keep your bank detail within
          its system. However, your profile information with us is securely
          encrypted using industry standard end to end encryption technology.
        </p>
        <!-- <p class="" @click="eligibility = 'never_applied'">click</p>
        {{ eligibility }} -->

        <form v-if="eligibility != 'pending' && eligibility != 'approved'">
          <div class="form-group bvn-group">
            <label for="bvn"
              >Please enter your eleven (11) digit BVN to check your credit
              status</label
            >
            <input
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="password"
              name="bvn"
              class="form-control"
              id="bvn"
              v-model="bvn"
              placeholder="BVN"
              maxlength="11"
              :disabled="diableBvnInput"
            />
            <i
              class="fa fa-lock password-icon"
              id="passwordVisibility"
              aria-hidden="true"
              @click="toggleLock($event)"
            ></i>
            <small id="emailHelp" class="form-text text-muted"
              >We'll never share your BVN with anyone else.</small
            >
          </div>
        </form>

        <div class="check_eligibility">
          <button
            v-if="
              !diableBvnInput &&
                eligibility != 'pending' &&
                eligibility != 'approved'
            "
            class="check_btn btn verifybvnbtn"
            @click="verifyBvn"
            :disabled="diableBvnBtn || bvn == '' || bvn.length < 11"
          >
            VERIFY BVN
            <i
              class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
              v-if="loader"
            ></i>
          </button>
          <aside class="matching_detials" v-if="diableBvnInput">
            <p>
              If you are {{ bvndata.FirstName }} {{ bvndata.SecondName }}
              {{ bvndata.OtherNames }} {{ bvndata.Surname }}, click on
              <span class="eligibility_span">CHECK ELIGIBILITY </span>button
              bellow to continue or
              <span class="cancel" @click="cancelEligibility">CANCEL</span>
            </p>
          </aside>
          <button
            v-if="
              diableBvnInput &&
                checkeligibility &&
                eligibility != 'pending' &&
                eligibility != 'approved'
            "
            class="check_btn btn"
            @click="eligibilityCheck"
            :disabled="diableBvnBtn || bvn == '' || bvn.length < 11"
          >
            CHECK ELIGIBILITY
            <i
              class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
              v-if="loader3"
            ></i>
          </button>
          <div v-if="eligibility != 'never_applied'">
            <p>
              Report:
            </p>
            <h5>{{ rentfinancingresponse }}</h5>
            <!-- <textarea disabled name="" id="" cols="" rows="" v-model="rentfinancingresponse2">

            </textarea> -->
            <!-- <p>{{ rentfinancingresponse2 }}</p> -->
          </div>
          <!-- data-toggle="modal" data-target="#success" -->

          <button
            v-if="eligibility === 'approved'"
            class="btn proceed"
            @click="proceedToApply = true"
            :disabled="eligibility != 'approved'"
          >
            PROCEED
            <i
              class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
              v-if="loader2"
            ></i>
          </button>
        </div>
      </div>

      <div v-if="pageLoader">
        <PageLoader></PageLoader>
      </div>
      <div>
        <div v-if="proceedToApply" class="rentpayemt_summary_holder">
          <h3 class="rent-sumaary_text">
            Rent Payment Details
          </h3>

          <hr />

          <div class="row">
            <!-- <div class="rent_details"> -->
            <div class="col-md-4">
              <img
                class="prop_img"
                :src="rent.oneimageurl"
                alt="property-image"
              />
            </div>

            <div class="col-md-8 prop_info">
              <h1>
                {{ rent.title }}
              </h1>
              <p>
                {{ rent.description }}
              </p>
              <small v-if="rent.state">
                {{ rent.state }}, {{ rent.city }}
              </small>
              <h6>&#8358;{{ Number(rent.price).toLocaleString() }}</h6>
              <small>Annually</small>
              <!-- <small>{{ rent. }}</small> -->
              <!-- <small>Payment due date: <span>15th June, 2021</span></small> -->
            </div>
          </div>

          <hr />
          <div>
            <p class="deposite_text">
              <span>Note*</span> <br />
              Security deposite is an amount paid when you agree to rent a
              house, apartment, etc., which its owner can keep if you leave
              before the end of the agreement or damage the property
            </p>
          </div>
          <hr />

          <div class="rate_holder">
            <div
              v-for="(mode, index) in rent.tenantmodeofpayments"
              :key="index"
            >
              <label
                >{{ mode.type }}
                <span
                  v-if="rent.tenantmodeofpaymentid == mode.id"
                  class="current_span"
                  >(current)</span
                ></label
              >
              <button
                @click="active(index, mode.id)"
                class="btn plan_btn"
                :class="[index === activeMode ? 'active_rate' : '']"
                :disabled="rent.tenantmodeofpaymentid == mode.id"
              >
                &#8358;{{ Number(mode.price).toLocaleString() }}
                <img
                  v-if="
                    rent.tenantmodeofpaymentid == mode.id || paymode == mode.id
                  "
                  src="../assets/images/circle-checked.svg"
                  alt=""
                />
                <img
                  v-if="
                    rent.tenantmodeofpaymentid != mode.id && paymode != mode.id
                  "
                  src="../assets/images/circle-unchecked.svg"
                  alt=""
                />
                <!-- <img
                  v-if="(rent.tenantmodeofpaymentid == mode.id)"
                  src="../assets/images/green-check-round.svg"
                  alt=""
                /> -->
              </button>
            </div>
          </div>

          <!-- Property amount summary begins -->
          <div
            class="summary"
            v-for="(prop, index) in rent.tenantmodeofpayments"
            :key="index"
          >
            <!-- v-if="paymode === prop.id" -->
            <div class="summary__details" v-if="paymode == prop.id">
              <div class="details__summary">
                <h3>Amount due</h3>
                <small>&#8358;{{ Number(prop.price).toLocaleString() }}</small>
              </div>
              <div class="details__summary">
                <h3>
                  Security deposit
                  <span class="refundable"> (Refundable)</span>
                  <span class="tooltiptext"
                    >An amount paid when you agree to rent a house, apartment,
                    etc., which its owner can keep if you leave before the end
                    of the agreement or damage the property.</span
                  >
                </h3>
                <small
                  >&#8358;{{
                    Number(prop.security_deposit).toLocaleString()
                  }}</small
                >
              </div>
              <!-- <div class="details__summary">
                <h3>Service charge</h3>
                <small
                  >&#8358;{{
                    Number(prop.service_charge).toLocaleString()
                  }}</small
                >
              </div> -->
              <div class="money__summary">
                <h3>First Payment</h3>
                <small class="d-none">{{
                  (securityDeposit = prop.security_deposit)
                }}</small>
                <small
                  >&#8358;{{
                    Number(prop.price + prop.security_deposit).toLocaleString()
                  }}</small
                >
              </div>
            </div>
          </div>
          <!-- Property amount summary ends -->

          <!-- Payment schedule -->
          <!-- v-if="rent.approved == '1'" -->
          <div class="payment_schedule_holder col-md-12">
            <div class="schedule_header" @click="openClose = !openClose">
              <h5>Rent Payment Breakdown</h5>
              <div>
                <i
                  class="fa fa-angle-up f-12 arrow_direction"
                  :class="[openClose ? 'fa-angle-up' : 'fa-angle-down']"
                ></i>
              </div>
              <!-- <img src="../assets/images/switch-icon.svg" alt="" /> -->
            </div>
            <div class="schedule_period" v-if="openClose">
              <div
                v-for="(item, index) in paymentshedules.schedule"
                class="color__item"
                :key="index"
              >
                <h6>
                  {{ item.date | moment }}
                  <span v-if="item.daysTO">({{ item.daysTO }} days away)</span>
                </h6>
                <small>&#8358;{{ Number(item.amount).toLocaleString() }}</small>
              </div>
            </div>
            <div class="schedule_footer" v-if="openClose">
              <h5>View All</h5>
              <h3>
                Total Payment: &#8358;{{
                  Number(totalpayment + securityDeposit).toLocaleString()
                }}
              </h3>
            </div>
          </div>

          <div class="col-md-12">
            <button
              ref="proceed"
              class="btn proceed_btn"
              @click="applyForRentFinancing()"
            >
              APPLY FOR RENT
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loader"
              ></i>
            </button>
          </div>
          <!-- Payment schedule -->
        </div>
      </div>

      <!-- Rent finance check modal data begin-->
      <div class="modal" tabindex="-1" id="success">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Rent Financing</h5>
              <hr />
            </div>
            <div class="modal-body notverified_body">
              <div class="check_icon">
                <img src="../assets/images/blue-tic.svg" alt="check" />
              </div>
              <div class="rent_successfull">
                <p>Rent Application</p>
                <h1>Successful</h1>
                <p>
                  Once your application is approved, Payment to your landlords
                  will be made and payment receipt sent to your email.
                </p>

                <p>Approval should be within 3 working days</p>
              </div>
            </div>
            <div class="modal-footer">
              <router-link to="/dashboard/rented">
                <button type="button" class="btn ok_btn" data-dismiss="modal">
                  OK
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Rent finance check modal data end -->
      <!-- <FooterMain /> -->
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import moment from "moment";
// import Header from "../views/Header";
import $ from "jquery";
import { Service } from "../store/service";
import { RouterLinkStub } from "@vue/test-utils";
const Api = new Service();

export default {
  name: "PayMyRentTenant",
  components: {
    SmallNav,
    PageLoader
  },
  data() {
    return {
      openClose: true,
      loader: false,
      loader2: false,
      loader3: false,
      pageLoader: false,
      diableBvnBtn: false,
      diableBvnInput: false,
      proceedToApply: false,
      checkeligibility: false,
      proceed: false,
      propertyDetails: "",
      rent: {},
      paymode: "",
      activeMode: "",
      rentfinancingresponse: "",
      modeId: 2,
      eligibility: "",
      ticket: "",
      bvn: "",
      schedules: "",
      schedulesArray: [],
      paymentshedules: [],
      totalpayment: "",
      securityDeposit: "",
      bvndata: ""
    };
  },
  mounted() {
    this.creditBureauCheck();
    this.activeMode = this.modeId;
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.propertyDetails = Api.getPropertyDetails();
    // console.log("details of prop from service:", this.propertyDetails);
    if (
      this.propertyDetails.id == undefined ||
      this.propertyDetails.id == null ||
      this.propertyDetails.rentFinancingObject.approvedforrentfinancing == "1"
    ) {
      this.$router.push({
        path: `/dashboard/rented`
      });
    } else {
      this.fetchPropertyDetails();
    }
    this.eligibility = this.propertyDetails.rentFinancingObject.eligibility;
    this.rentfinancingresponse = this.propertyDetails.rentFinancingObject.rentfinancingresponse;
    // console.log("eligility status:", this.eligibility);
    // if (this.propertyDetails.rentFinancingObject.eligibility == "never_applied") {
    //   this.proceed = false;
    //   this.eligibilityCheck = false;
    // } else {
    //   this.proceed = true;
    //   this.eligibilityCheck = true;
    // }
    // this.limit('');
  },
  methods: {
    // BVN visibilty
    toggleLock: function(e) {
      if (this.bvn != "") {
        // let x = this.bvn;
        // this.bvn = x;
        // console.log("Hello", this.bvn, x);
        const element = document.querySelector(".password-icon");
        this.shown = !this.shown;
        // e.stopPropagation();
        // const bvn = new RegExp("^[0-9]*$");
        if (this.shown) {
          document.getElementsByName("bvn")[0].setAttribute("type", "number");
          element.classList.remove("fa-lock");
          element.classList.add("fa-unlock");
          // console.log("Number type");
          // this.bvn = x;
          // console.log("Hello1", this.bvn, x);
          // e.stopPropagation();
        } else {
          document.getElementsByName("bvn")[0].setAttribute("type", "password");
          element.classList.remove("fa-unlock");
          element.classList.add("fa-lock");
          // console.log("password type");
          // this.bvn = x;
          // console.log("Hello2", this.bvn, x);
        }
      }
    },
    async creditBureauCheck() {
      try {
        await Api.getRequest(`getcreditbeareauticket`)
          .then(res => {
            if (res.data.success === "login successful") {
              this.ticket = res.data.ticket;
              // console.log("credit ticket on init", this.ticket);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.diableBvnBtn = false;
          });
      } finally {
        // console.log(closed);
      }
    },
    async verifyBvn() {
      const bvn = new RegExp("^[0-9]*$");
      this.diableBvnBtn = true;
      this.loader = true;
      if (bvn.test(this.bvn) == false) {
        this.loader = false;
        this.diableBvnBtn = false;
        this.$store.commit("setApiFailed", "BVN must be in numeric form");
        // alert("Only Numeric Ticket ID is allowed.");
      } else {
        if (this.ticket === "" || this.bvn === "" || this.bvn.length < 11) {
          console.log("No data");
          this.diableBvnBtn = true;
          this.creditBureauCheck();
          this.confirmcreditcheck();
        } else {
          try {
            await Api.postRequest(`creditbeareuverifyusingbvn`, {
              bvn: this.bvn,
              ticket: this.ticket
            })
              .then(res => {
                this.loader = false;
                // console.log("bvn verification:", res);
                if (res.status == 200) {
                  this.bvndata = res.data.data[0].MatchedConsumer[0];
                  const fname = this.bvndata.FirstName;
                  const sname = this.bvndata.SecondName;
                  const accountnumber = this.bvndata.AccountNo;
                  const consumerid = this.bvndata.ConsumerID;
                  // const fname = this.bvndata.FirstName;

                  if (
                    fname === null ||
                    (sname === null &&
                      accountnumber === null &&
                      consumerid === null)
                  ) {
                    this.$store.commit(
                      "setApiFailed",
                      "BVN does not exist or is Invalid"
                    );
                    this.diableBvnBtn = false;
                    this.checkeligibility = false;
                    this.diableBvnInput = false;
                  } else {
                    this.diableBvnInput = true;
                    this.checkeligibility = true;
                    // this.eligibilityCheck();
                    this.diableBvnBtn = false;
                  }
                }
              })
              .catch(err => {
                console.log("err", err);
                this.diableBvnInput = false;
                this.checkeligibility = false;
                this.diableBvnBtn = false;
                this.loader = false;
              });
          } finally {
            // console.log(closed);
          }
        }
      }
    },
    async confirmcreditcheck() {
      try {
        await Api.postRequest(`creditbeareuverifyusingbvn`, {
          bvn: this.bvn,
          ticket: this.ticket
        })
          .then(res => {
            this.loader = false;
            if (res.status == 200) {
              this.bvndata = res.data.data[0].MatchedConsumer[0];
              const fname = this.bvndata.FirstName;
              const sname = this.bvndata.SecondName;
              const accountnumber = this.bvndata.AccountNo;
              const consumerid = this.bvndata.ConsumerID;
              // const fname = this.bvndata.FirstName;

              if (
                fname === null ||
                (sname === null &&
                  accountnumber === null &&
                  consumerid === null)
              ) {
                this.$store.commit(
                  "setApiFailed",
                  "BVN does not exist or is Invalid"
                );
                this.diableBvnBtn = false;
                this.checkeligibility = false;
                this.diableBvnInput = false;
              } else {
                this.diableBvnInput = true;
                this.checkeligibility = true;
                // this.eligibilityCheck();
                this.diableBvnBtn = false;
              }
            }
          })
          .catch(err => {
            console.log("err", err);
            this.diableBvnInput = false;
            this.checkeligibility = false;
            this.diableBvnBtn = false;
            this.loader = false;
          });
      } finally {
        // console.log(closed);
      }
    },
    cancelEligibility() {
      this.diableBvnBtn = false;
      this.checkeligibility = false;
      this.diableBvnInput = false;
    },
    async eligibilityCheck() {
      this.loader3 = true;
      try {
        await Api.postRequest(`bvnelegibilitycheck`, {
          bvn: this.bvn,
          propertyid: this.propertyDetails.id
        })
          .then(res => {
            this.loader3 = false;
            // console.log("Eligibility check", res);
            this.rentfinancingresponse = res.data.data.rentfinancingresponse;
            if (res.data.success == "success") {
              return this.$store.commit(
                "setApiSuccess",
                "Successful, waiting for approval"
              );
            }
            this.$router.push({
              path: `/dashboard/rented`
            });
            // this.fetchTenantProperty();
          })
          .catch(err => {
            console.log("err", err);
            this.diableBvnBtn = false;
            this.loader3 = false;
          });
      } finally {
        // console.log('closed');
      }
    },
    // async fetchTenantProperty() {
    //   try {
    //     await Api.getRequest(`getpropertybyid/${this.propertyDetails.id}`)
    //       .then((res) => {
    //         console.log("Property details", res);
    //         if (res.data.sucess) {
    //           console.log("Property details", res);
    //           // console.log("propeligibility");
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   } finally {
    //     // console.log("Closed connection");
    //   }
    // },
    active(data, mode) {
      this.activeMode = data;
      this.modeId = mode;
      this.paymode = mode;
      this.calculations();
      // console.log("Selected mode", this.modeId);
    },
    calculations() {
      // console.log("Schedule", this.schedules, "Paymode", this.paymode);
      // const raba = this.schedules.filter((data) => data.id == this.paymode);
      const raba = this.schedules.filter(data => data.id == 2);
      // console.log("RABA", raba);
      this.paymentshedules = raba[0];
      // console.log("Filter", raba);
      this.totalpayment = raba[0].schedule.reduce((sum, current) => {
        return sum + parseInt(current.amount);
      }, 0);
      //  console.log("Total amount", this.totalpayment);
    },
    back() {
      this.$router.push({
        path: `/dashboard/rented`
      });
    },
    async fetchPropertyDetails() {
      this.pageLoader = true;
      try {
        const pin = this.propertyDetails.pin;
        Api.getRequest(`paymentsummaryforrentfinance/${pin}`)
          .then(res => {
            if (res.data.success) {
              this.pageLoader = false;
              console.log("details of property", res);
              this.rent = res.data.property;
              // this.modeId = res.data.property.tenantmodeofpaymentid;
              this.paymode = res.data.property.tenantmodeofpaymentid;
              this.schedules = res.data.property.tenantmodeofpayments;
              // this.calculations();
            }
            // console.log("mode", this.modeId);
          })
          .catch(err => {
            console.log(err);
            this.pageLoader = false;
            // this.$store.commit("setApiFailed", res.data.error);
          });
      } finally {
        // console.log("closed");
      }
    },
    async applyForRentFinancing() {
      this.loader = true;
      // console.log("Mode Id", this.modeId);
      if (this.modeId === "") {
        return this.$store.commit("setApiFailed", "select mode of payment");
      }
      try {
        await Api.postRequest(`applyforrentfinancing`, {
          userid: this.id,
          propertyid: this.propertyDetails.id,
          modeofpaymentid: parseInt(this.modeId)
        })
          .then(res => {
            // console.log("response apply for rent", res);
            this.loader = false;
            if (res.data.success) {
              this.loader = false;
              $("#success")
                .addClass("fade")
                .modal("show");
              return this.$store.commit("setApiSuccess", "success");
            } else {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
            this.$store.commit("setApiFailed", err.data.error);
          });
      } finally {
        // console.log("closed");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
    // moment: function(date) {
    //   return moment(date).format("MMMM DD YYYY, h:mm:ss a");
    // },
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.properties_count {
  text-align: left;
  padding: 30px 20px;
  cursor: pointer;
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
    span {
      color: $primary;
    }
  }
}

.pmr-intro {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 50px;
  margin: 0 20px;
  p {
    margin-bottom: 0;
    text-align: left;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
  }
}

.eligibilty_holder {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 743px;
  width: 100%;
  margin: 45px auto;
  padding: 25px 26px;

  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: $black;
    padding-top: 15px;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    color: $black;
  }

  form {
    margin-top: 40px;
    label {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $black;
      text-align: left;
    }
    input {
      border-radius: 0;
      border: 0.2px solid #6c6c6c;
      box-shadow: none;
      outline: 0;
    }
  }
}

.matching_detials {
  p {
    font-family: Gotham;
    font-weight: normal;
    // font-size: 18px;
    font-size: 14px;
    text-align: left;
    color: $black;
    line-height: 30px;
  }
}

.eligibility_span {
  color: $primary;
}
.cancel {
  color: #ea0000;
  cursor: pointer;
  font-weight: bold;
}
.cancel:hover {
  color: #780303;
  font-size: 14px;
}
.check_eligibility {
  margin-top: 48px;
  div {
    // max-width: 687px;
    width: 100%;
    // min-height: 360px;
    height: 100%;
    background: rgba(0, 51, 234, 0.1);
    border-radius: 5px;
    margin-bottom: 27px;
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 30px;
      align-items: center;
      text-align: left;
      color: $black;
      padding: 18px 35px;
      background: inherit;
      margin-bottom: 0;
    }
    h5 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      align-items: center;
      text-align: left;
      color: $black;
      padding: 0px 35px 18px;
      background: inherit;
      margin-bottom: 0;
      word-break: break-all;
      white-space: normal;
    }
    // textarea {
    //   width: 100%;
    //   border: none;
    // /* height: 100%; */
    //   padding: 30px;
    //   background: inherit;
    //   height: inherit;
    //   outline: 0;
    //   box-shadow: none;
    // }
  }

  .verifybvnbtn {
    color: $black !important;
    background: $yellow !important;
  }
  .check_btn {
    display: block;
    // max-width: 228px;
    width: 100%;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 30px;
    outline: 0;
    box-shadow: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .proceed {
    // display: flex;
    float: right;
    justify-content: right;
    width: 166px;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 30px;
    box-shadow: none;
    outline: 0;
  }
}

.rentpayemt_summary_holder {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 743px;
  width: 100%;
  margin: 45px auto;
  padding: 25px 26px;
}

.rent-sumaary_text {
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.prop_img {
  // max-width: 224px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  max-width: 224px;
  max-height: 165px;
  height: 100%;
}

.prop_info {
  text-align: left;
  h1 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 5px;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 50px;
    margin-bottom: 0;
    color: $black;
  }
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: $black;
    span {
      font-weight: bold;
      padding-left: 5px;
    }
  }
}

.deposite_text {
  font-family: Gotham;
  font-weight: normal;
  text-align: left;
  color: $black;
  font-size: 13px;
  span {
    color: #ea0000;
  }
}

.deposite_text {
  span {
    animation: blinker 1s linear infinite;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.rate_holder {
  margin-top: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  div:not(:last-child) {
    margin-right: 10px;
  }
  label {
    display: flex;
  }
}
.current_span {
  font-size: 11px;
  display: flex;
  align-items: center;
  color: #a2b0e3;
  padding-left: 5px;
}

.active_rate {
  border: 1px solid $primary !important;
  color: $primary !important;
}
.rent_content_active {
  color: $primary;
}
.plan_btn {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

  color: #c4c4c4;
  // background: #f5f5f5;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  min-width: 156.68px;
  width: 100%;
  height: 50px;
  box-shadow: none;
  outline: 0;
  align-items: center;
  margin-bottom: 23px;
  img {
    padding-left: 7.5px;
  }
}

.summary__details {
  padding-top: 3rem;
  .details__summary {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #c4c4c4;
    margin: 0 15px;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
  }
  .money__summary {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    margin: 0 15px;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    small {
      font-weight: bold;
    }
  }
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    // align-items: center;
    // text-align: center;
    color: #000000;
  }
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    // align-items: center;
    // text-align: center;
    color: #000000;
  }
}
.refundable {
  color: #c4c4c4;
  padding-left: 0.5rem;
  font-size: 12px;
  position: relative;
  cursor: pointer;
}

.bvn-group {
  position: relative;
}
.password-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 34% !important;
  right: 3% !important;
}
.password-icon::before {
  cursor: pointer;
}
// .tooltip {
//   position: relative;
//   display: inline-block;
//   border-bottom: 1px dotted black;
// }

.tooltiptext {
  visibility: hidden;
  width: 200px;
  background: #f2f5fe;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  /* or 237% */

  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: #000000;
  text-align: center;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  top: 79%;
  left: 32%;
  margin-left: -60px;
}

.refundable + .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.refundable:hover + .tooltiptext {
  visibility: visible;
}

.paynow {
  text-align: right;
  button {
    background: $primary;
    border-radius: 5px;
    color: $secondary;
    outline: 0;
    box-shadow: none;
    // width: 100%;
    // height: fit-content;
    width: 166px;
    height: 40px;

    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
}
/* Proprty amount summary ends */

.proceed_btn {
  background: $primary;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: $secondary;
  // width: 100%;
  margin-bottom: 45px;
  height: 50px;
  outline: 0;
  box-shadow: none;
}
.payment_schedule_holder {
  // margin-top: 30px;
  margin-bottom: 27px;
  margin: 30px auto 27px;
}

.schedule_header {
  cursor: pointer;
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    margin-bottom: 0;
  }
}
.arrow_direction {
  color: $secondary;
  font-size: 24;
  font-weight: bold;
}
.schedule_period {
  // transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 17px;
    h6 {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
      margin-bottom: 0;
    }
    small {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
    }
  }
}

.schedule_footer {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 0px 0px 10px 10px;
  // transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
  }
  h3 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
  }
}

.color__item {
  &:nth-of-type(1),
  &:nth-child(2n + 1) {
    background: linear-gradient(0deg, #efefef, #efefef),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%) !important;
  }
  &:nth-of-type(2),
  &:nth-child(2n + 2) {
    background: $secondary !important;
  }
}
// PAYMENT SCHEDULE

.modal-title {
  font-family: Gotham;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
}
.check_icon {
  padding-top: 3rem;
  padding-bottom: 8px;
}
.rent_successfull {
  padding-bottom: 40px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 5px;
    // font-size: 20px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    color: $primary;
  }
  h1 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    padding-top: 15px;
    // font-size: 20px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    color: $primary;
  }
  p:nth-child(3) {
    margin-top: 45px;
  }
  p:nth-child(4) {
    padding-top: 15px;
  }
}

.ok_btn {
  background: $yellow;
  color: $black;
  font-family: Gotham;
}
// Success rent finacing btn ends

::-webkit-input-placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  align-items: center;
  color: $black;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  align-items: center;
  color: $black;
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  align-items: center;
  color: $black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button:disabled {
  cursor: not-allowed;
}
input:disabled {
  cursor: not-allowed;
}

@media only screen and (max-width: 599px) {
  .text-muted {
    text-align: left;
  }
  .prop_info {
    margin-top: 20px;
  }
  .payment_schedule_holder {
    padding: 0;
  }
  .schedule_period {
    div {
      h6 {
        font-size: 10px;
      }
    }
  }
  .rate_holder {
    justify-content: left;
  }
  .summary__details {
    padding-top: 1rem;
  }
  .summary__details {
    h3 {
      font-size: 12px;
    }
    small {
      font-size: 12px;
    }
  }
  .password-icon {
    bottom: 27%;
  }
  // .rentpayemt_summary_holder {
  //   margin: 45px 20px;
  // }
  .check_eligibility {
    margin-top: 25px;
    .check_btn {
      margin-bottom: 70px;
    }
  }
}
</style>
